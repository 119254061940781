<template>
  <section>
    <div>
      <v-img src="../../assets/img/Vector6.png">
        <v-col
          cols="12"
          lg="6"
          md="10"
          sm="12"
          style="margin-left: 40px; margin-top: 40px"
        >
          <v-img
            src="../../assets/img/logo-secretaria.png"
            width="200px"
          ></v-img>
        </v-col>
        <v-row class="pb-3" justify="center">
          <v-col cols="12" lg="5" md="6" sm="12">
            <v-row class="contenido-inicio" justify="center">
              <v-col cols="12" sm="12" md="10">
                <p
                  id="mi-parrafo"
                  class="text-color pt-0 text-left titulo text-center text-md-start"
                >
                  ¡EL EMPLEO DE TUS <br />
                  SUEÑOS ESTA A SOLO <br />
                  <B>UN CLIC DE DISTANCIA!</B>
                </p>
                <v-col style="justify-content: space-between"> </v-col>
                <div id="botones" class="d-flex justify-left">
                  <v-row class="justify-center justify-md-start">
                    <v-col
                      cols="7"
                      class="d-flex justify-center justify-md-start flex-column flex-md-row"
                      style="gap: 20px"
                    >
                      <v-btn
                        class="mi-boton text-none"
                        x-large
                        color="#22234a"
                        v-if="token == null"
                        outlined
                        rounded
                        @click="login()"
                      >
                        <span class="pa-5">Iniciar sesión</span>
                      </v-btn>
                      <v-btn
                        class="mi-boton text-none"
                        x-large
                        color="#22234a"
                        v-if="token == null"
                        rounded
                        dark
                        @click="
                          abrirRegistrarme(
                            `${BASE_URL_ID}/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL.replace(
                              '/',
                              '%2F'
                            )}`
                          )
                        "
                        :loading="loading"
                      >
                        <span class="pa-5">Registrarme</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col id="mi-col" cols="11" lg="7" md="6" sm="12">
            <v-img
              src="../../assets/img/doctora landing.svg"
              style="margin-top: -35px"
            ></v-img>
          </v-col>
        </v-row>
      </v-img>
    </div>
    <ChatComponent />
    <!-- <div>
            <v-img src="../../assets/img/wave.svg"></v-img>
      </div> -->
    <v-row class="mt-12 ml-2 justify-center justify-md-start">
      <v-col cols="10" sm="12" md="7" lg="8">
        <v-row>
          <v-col
            v-for="(item, index) in items"
            :key="index"
            cols="12"
            sm="4"
            md="4"
            lg="4"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 12 : 2"
                style="background-color: rgba(13, 153, 255, 1)"
                @click="filtrarArea(item.nombre)"
              >
                <v-img
                  :src="item.imagen"
                  position="background-position"
                  height="300px"
                  class="cuadro"
                >
                  <v-card-text>
                    <v-row justify="center">
                      <v-col class="d-flex justify-center align-center">
                        <div
                          v-if="hover"
                          class="text-position"
                          style="font-size: 1.5em; font-weight: bold"
                        >
                          <span class="texto-blanco-intenso">{{
                            item.nombre.toUpperCase()
                          }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="d-flex justify-center align-center"
        cols="12"
        sm="12"
        md="5"
        lg="4"
      >
        <div>
          <v-row>
            <v-col>
              <p
                class="pb-0 mb-0 text-center text-md-start titulo"
                style="color: #1c1e4d; font-weight: 900"
              >
                ÁREAS DE INTERÉS
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p
                class="pt-0 parrafo text-center text-md-start"
                style="word-break: break-word; color: #313945"
              >
                Si buscas trabajo ¡RH Plataforma Nacional es tu aliado!
              </p>
              <p
                class="text-color pt-0 parrafo text-center text-md-start"
                style="word-break: break-word"
              >
                Muchas ofertas de empleo están esperándote
              </p>
            </v-col>
          </v-row>
          <v-row class="justify-center justify-md-start">
            <v-col class="d-flex justify-center justify-md-start">
              <v-btn
                class="font-weight-bold"
                color="#22234a"
                x-large
                outlined
                rounded
                :to="{ path: '/oferta-empleos' }"
                >Ver más empleos
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <ModalRegistrarmeComponent :modal="modalRegistro" @cerrar="cerrar()" />
    <AppFooter></AppFooter>
  </section>
</template>

<script>
import { mapState } from "vuex";
import AppFooter from "../../components/AppFooter.vue";
import ChatComponent from "../../components/ChatComponent.vue";
import ModalRegistrarmeComponent from "./components/modalRegistrarmeComponent.vue";

export default {
  data: () => ({
    REDIRECT_URL: process.env.VUE_APP_ID_REDIRECT_URL,
    BASE_URL_ID: process.env.VUE_APP_IDENTIDAD_DIGITAL_URL,
    CLIENT_ID: process.env.VUE_APP_CLIENT_ID,
    transparent: "rgba(255, 255 ,255 ,0)",
    modalRegistro: false,
    items: [
      {
        nombre: "Informática",
        imagen:
          "https://humanidades.com/wp-content/uploads/2018/08/informatica-e1575679570321.jpg",
      },
      {
        nombre: "Salud",
        imagen:
          "https://www.cepal.org/sites/default/files/styles/1920x1080/public/images/featured/salud2.jpg?itok=Qi2e3ZfN",
      },
      {
        nombre: "Ventas",
        imagen:
          "https://www.sydle.com/blog/assets/post/lean-startup-concept-principles-and-how-to-correctly-apply-it-6350b9714037f135694be5d2/lean-startup.jpg",
      },
      {
        nombre: "Call Center",
        imagen:
          "https://www.esic.edu/sites/default/files/rethink/3e0af5ef-call-center-contact-center.jpg",
      },
      {
        nombre: "Construcción",
        imagen:
          "https://realestatemarket.com.mx/images/2021/08-agosto/3008/_Tecnologa_clave_en_seguridad_de_trabajadores_de_la_construccin__.png",
      },
      {
        nombre: "Finanzas",
        imagen:
          "https://cdn.shortpixel.ai/spai/w_818+q_+ret_img+to_webp/https://www.eude.es/wp-content/uploads/2022/03/NOTICIAS-EUDE-2.png",
      },
    ],
  }),
  components: {
    ModalRegistrarmeComponent,
    AppFooter,
    ChatComponent,
  },
  methods: {
    filtrarArea(nombre) {
      if (nombre) {
        this.$router.push({
          path: "/oferta-empleos",
          query: { area: nombre },
        });
      }
    },
    login() {
      this.$router.push("login");
    },
    async abrirRegistrarme(url) {
      //console.log(url);
      window.open(url, "_parent");
    },
    cerrar() {
      this.modalRegistro = false;
    },
  },
  computed: {
    ...mapState(["token"]),
  },
};
</script>

<style scoped>
.text-color {
  color: #22234a !important;
}
.container-header {
  top: 0 !important;
  width: 100%;
  background-position-y: 54px;
}
.wave {
  background: url(../../assets/img/wave.svg);
  width: 100% !important;
  background-size: cover;
}
.show-name {
  color: rgba(255, 255, 255, 1) !important;
}
.text-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: white;
}
.contenido-inicio {
  margin-top: 250px;
  margin-left: 150px;
}
.cuadro {
  opacity: 1;
  border-radius: 25px;
}
.cuadro:hover {
  opacity: 0.8;
}
#mi-parrafo {
  /* font-size: 43px; */
  margin-left: -140px;
  margin-top: -210px;
  line-height: 1.2;
}
.d-flex.justify-left {
  margin-left: -140px;
}
.texto-blanco-intenso {
  color: #fff !important;
  opacity: 1 !important;
  text-shadow: 2px 2px 2px black;
}
.titulo {
  font-size: 2em;
}
@media (min-width: 600px) {
  .titulo {
    font-size: 2em;
  }
}
@media (min-width: 900px) {
  .titulo {
    font-size: 2.5em;
  }
}
@media (min-width: 1320px) {
  .titulo {
    font-size: 3em;
  }
}
@media (min-width: 1500px) {
  .titulo {
    font-size: 3.6em;
  }
}
.parrafo {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6rem;
  letter-spacing: 0.0125em;
}
@media (min-width: 600px) {
  .parrafo {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.333rem;
    letter-spacing: normal;
  }
}
@media (min-width: 900px) {
  .parrafo {
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 1.175;
    letter-spacing: 0.0073529412em;
  }
}
</style>
